import $ from 'jquery';
import './jqueryLoad';
import fancybox from '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox';
import '../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import '../node_modules/slick-carousel/slick/slick';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import AOS from '../node_modules/aos/dist/aos';
import '../node_modules/aos/dist/aos.css';

$(document).ready(function() {
  // AOS

  AOS.init();
  // GALLERY

  $().fancybox({
    selector: '.area__slider .slick-slide:not(.slick-cloned) a',
    backFocus: false,
    loop: true,
    buttons: ['slideShow', 'close'],
    clickContent: false
  });

  $().fancybox({
    selector: '.interior__slider .slick-slide:not(.slick-cloned) a',
    backFocus: false,
    loop: true,
    buttons: ['slideShow', 'close'],
    clickContent: false
  });

  $().fancybox({
    selector: '.home__slider .slick-slide:not(.slick-cloned) a',
    backFocus: false,
    loop: true,
    buttons: ['slideShow', 'close'],
    clickContent: false
  });

  $('.gallery__slider').slick({
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    draggable: false
  });

  $('.jumbotron__slider').slick({
    infinite: true,
    slidesToShow: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000
  });

  $('.map__slider').slick({
    infinite: true,
    slidesToShow: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          infinite: true,
          slidesToShow: 1,
          draggable: true,
          autoplay: true,
          autoplaySpeed: 6000,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: 'unslick'
      }
    ]
  });

  // MENU

  window.addEventListener('scroll', () => {
    if (window.scrollY > window.innerHeight - 100) {
      $('#header').addClass('header__scrolled');
    } else if (window.scrollY <= window.innerHeight - 100) {
      $('#header').removeClass('header__scrolled');
    }
  });

  // icon click

  let clickDisabled = false;
  $('#nav-icon4').on('click', () => {
    if (!clickDisabled) {
      $('#nav-icon4').toggleClass('open');
      $('#mobile-navigation').toggleClass('menu_opened');
      $('body').toggleClass('scroll__block');

      clickDisabled = true;
      setTimeout(function() {
        clickDisabled = false;
      }, 500);
    }
  });

  $('.mobile-navigation__item').on('click', () => {
    $('#nav-icon4').removeClass('open');
    $('#mobile-navigation').removeClass('menu_opened');
    $('body').removeClass('scroll__block');
  });

  $(window).resize(() => {
    if ($(window).width() >= 1024) {
      $('#nav-icon4').removeClass('open');
      $('#mobile-navigation').removeClass('menu_opened');
      $('body').removeClass('scroll__block');
    }
  });

  // HEADER COLOR CHANGE

  // SMOOTH SCROLL

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });

  // IMAGE MAP

  // var imageMapItems = document.querySelectorAll('.image-map__item');

  // imageMapItems.forEach(imageMapItem => {
  //   imageMapItem.addEventListener('mouseenter', function(event) {
  //     var cloudClass = '.cloud-' + event.target.dataset.indexNumber;
  //     $(cloudClass).css({ left: event.pageX, top: event.pageY - 50, visibility: 'visible' });
  //   });

  //   imageMapItem.addEventListener('mouseleave', function(event) {
  //     var cloudClass = '.cloud-' + event.target.dataset.indexNumber;
  //     $(cloudClass).css({ visibility: 'hidden' });
  //   });
  // });

  // CONTACT FORM

  // VALIDATION

  //   const firstName = document.getElementById('input__firstname');
  //   const lastName = document.getElementById('input__lastname');
  //   const emailAddress = document.getElementById('input__email');
  //   const sentMessage = document.getElementById('input__message');
  //   const submitButton = document.getElementById('submit-button');
  //   const formCheckbox = document.getElementById('formCheckbox');

  //   const regExp = /^([-!#-\'*+\/-9=?A-Z^-~]{1,64}(\.[-!#-\'*+\/-9=?A-Z^-~]{1,64})*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]{2,}([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/;

  //   const addError = (errorTarget, isError) => {
  //     if (!isError) {
  //       errorTarget.classList.add('error');
  //     }
  //   };

  //   const validateName = inputToValidate => {
  //     if (inputToValidate.value.length === 0) {
  //       // inputToValidate.classList.add('error');
  //       return false;
  //     } else if (
  //       inputToValidate.value.match(/[^a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]/g) ||
  //       inputToValidate.value.length < 3 ||
  //       inputToValidate.value.length > 51
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };
  //   const validateEmail = inputToValidate => {
  //     if (inputToValidate.value.match(regExp)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };

  //   const validateCheckbox = () => {
  //     if (!formCheckbox.checked) {
  //       formCheckbox.parentNode.parentNode.classList.add('error');
  //       return false;
  //     } else {
  //       formCheckbox.parentNode.parentNode.classList.remove('error');
  //       return true;
  //     }
  //   };

  //   const validateMessage = inputToValidate => {
  //     if (inputToValidate.value.length === 0) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };

  //   // focus input
  //   firstName.addEventListener('focus', event => {
  //     event.target.classList.remove('error');
  //   });
  //   lastName.addEventListener('focus', event => {
  //     event.target.classList.remove('error');
  //   });
  //   emailAddress.addEventListener('focus', event => {
  //     event.target.classList.remove('error');
  //   });
  //   sentMessage.addEventListener('focus', event => {
  //     event.target.classList.remove('error');
  //   });

  //   // blur input
  //   firstName.addEventListener('blur', event => addError(event.target, validateName(event.target)));
  //   lastName.addEventListener('blur', event => addError(event.target, validateName(event.target)));
  //   emailAddress.addEventListener('blur', event => addError(event.target, validateEmail(event.target)));
  //   sentMessage.addEventListener('blur', event => addError(event.target, validateMessage(event.target)));

  //   // submit

  //   submitButton.addEventListener('click', event => {
  //     event.preventDefault();

  //     const valFirstName = validateName(firstName);
  //     const valLastName = validateName(lastName);
  //     const valEmail = validateEmail(emailAddress);
  //     const valMessage = validateMessage(sentMessage);
  //     const valCheckbox = validateCheckbox();

  //     addError(firstName, valFirstName);
  //     addError(lastName, valLastName);
  //     addError(emailAddress, valEmail);
  //     addError(sentMessage, valMessage);

  //     if (valFirstName && valLastName && valEmail && valMessage && valCheckbox) {
  //       // if (false) {
  //       $.ajax({
  //         url: 'http://www.bagryhouse.hekko24.pl/php/src/SendMessage.php',
  //         type: 'POST',
  //         data: {
  //           name: firstName.value + ' ' + lastName.value,
  //           email: emailAddress.value,
  //           message: sentMessage.value
  //         }
  //       })
  //         .done(function(response) {
  //           console.log(response);
  //         })
  //         .fail(function() {
  //           // $('.form-message').html( "Wystąpił błąd" ); //pokazujemy użytkownikowi komunikat
  //         });
  //     } else {
  //       console.log('formularz został zablokowany');
  //     }
  // });
});
